
.comfortaa  {
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
body {
  margin: 0;
  font-family: "Comfortaa", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cb-purple {
  color: #573185
}
.cb-blue {
  color: #0176be;
}

.img-circle {
  border-radius: 50%;
  max-width: 200px;
  border: 1px solid #ccc 
}

/* .justify-text {
  text-align: justify;
} */